<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-menu offset-y>
        <template #activator="{on, attrs}">
          <v-btn
            absolute
            top
            right
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in EventType"
            :key="index"
            @click="create(item.value)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="[
          { text: 'Name', value: 'name', sortable: true },
          { text: 'Typ', value: 'type', sortable: false },
          { text: 'Start', value: 'startDate', sortable: true },
          { text: 'Ende', value: 'endDate', sortable: true }
        ]"
        :items="events"
        mobile-breakpoint="0"
        sort-by="startDate"
        sort-desc
        :search="filter"
        :items-per-page="15"
        @click:row="open"
      >
        <template #item.name="{item}">
          <span style="font-weight: bold;">{{ item.name }}</span>
        </template>
        <template #item.type="{item}">
          {{ EventType.find(t => t.value === item.type).text }}
        </template>
        <template #item.startDate="{item}">
          {{ item.startDate | date }}
        </template>
        <template #item.endDate="{item}">
          {{ item.endDate | date }}
        </template>
      </v-data-table>
    </v-card>
    <event
      v-model="dialog.open"
      :id="dialog.id"
    />
  </v-container>
</template>

<script>
import { apolloDefault, useGraphQL } from '@/plugins/graphql'
import Event from '@/views/components/admin/dialogs/event'
import gql from 'graphql-tag'
import moment from 'moment'
import { displayType, displayTypes } from '@/main'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbM2021 {
    name
    startDate endDate
  }
  ... on StbW2023 {
    name
    startDate endDate
  }
  ... on StbLtf2024 {
    name
    startDate endDate
  }
`

export default {
  name: 'events',

  components: {
    Event
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    EventFind: [],
    filter: '',
    dialog: {
      open: false,
      id: ''
    }
  }),

  computed: {
    EventType () { return displayTypes },
    events () {
      return this.EventFind.filter(e => displayType(e.type))
    }
  },

  methods: {
    open (item) {
      this.dialog.id = item._id
      this.dialog.open = true
    },
    async create (type) {
      switch (type) {
        case 'stb_m_2021': {
          const datum = moment().format('YYYY-MM-DD')
          const id = await this.mutate({
            mutation: gql`
              mutation($name: String!, $startDate: Date!, $endDate: Date!) {
                StbM2021Create(name: $name, startDate: $startDate, endDate: $endDate) { _id }
              }
            `,
            variables: {
              name: 'Neues Event',
              startDate: datum,
              endDate: datum
            }
          })
          this.open(id.StbM2021Create)
        } break
        case 'stb_w_2023': {
          const datum = moment().format('YYYY-MM-DD')
          const id = await this.mutate({
            mutation: gql`
              mutation($name: String!, $startDate: Date!, $endDate: Date!) {
                StbW2023Create(name: $name, startDate: $startDate, endDate: $endDate) { _id }
              }
            `,
            variables: {
              name: 'Neues Event',
              startDate: datum,
              endDate: datum
            }
          })
          this.open(id.StbW2023Create)
        } break
        case 'stb_ltf_2024': {
          const datum = moment().format('YYYY-MM-DD')
          const id = await this.mutate({
            mutation: gql`
              mutation($name: String!, $startDate: Date!, $endDate: Date!) {
                StbLtf2024Create(name: $name, startDate: $startDate, endDate: $endDate) { _id }
              }
            `,
            variables: {
              name: 'Neues Event',
              startDate: datum,
              endDate: datum
            }
          })
          this.open(id.StbLtf2024Create)
        } break
      }
    }
  },

  apollo: apolloDefault('Event', query, '(parent: null)')
}
</script>

<style scoped>

</style>
