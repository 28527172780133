<template>
  <base-material-dialog
    :value="value"
    icon="far fa-calendar"
    title="Hauptevent bearbeiten"
    :sub-title="id ? id : 'NEU'"
    color="primary"
    :actions="[ 'del', 'close' ]"
    @close="close"
    @esc="close"
    @del="del"
  >
    <template v-if="!isMaster">
      Kein Zugriff!
    </template>
    <v-tabs
      v-else
      v-model="tab"
      grow
      icons-and-text
      background-color="grey lighten-3"
      color="primary"
      show-arrows
    >
      <v-tabs-slider />
      <v-tab
        v-if="Event.type === 'stb_m_2021'"
        :href="`#tab-stbm2021`"
      >
        STB männlich (v2021)
        <v-icon>far fa-info-circle</v-icon>
      </v-tab>
      <v-tab
        v-if="Event.type === 'stb_w_2023'"
        :href="`#tab-stbw2023`"
      >
        STB weiblich (v2023)
        <v-icon>far fa-info-circle</v-icon>
      </v-tab>
      <v-tab
        v-if="Event.type === 'stb_ltf_2024'"
        :href="`#tab-stbltf2024`"
      >
        Landesturnfest (v2024)
        <v-icon>far fa-info-circle</v-icon>
      </v-tab>
      <v-tab
        v-if="id"
        :href="`#tab-ids`"
      >
        IDs
        <v-icon>far fa-fingerprint</v-icon>
      </v-tab>

      <v-tab-item value="tab-stbm2021">
        <stb-m2021 :Event="Event" :id="id" />
      </v-tab-item>
      <v-tab-item value="tab-stbw2023">
        <stb-w2023 :Event="Event" :id="id" />
      </v-tab-item>
      <v-tab-item value="tab-stbltf2024">
        <stb-ltf2024 :Event="Event" :id="id" />
      </v-tab-item>

      <v-tab-item value="tab-ids">
        <identifiers
          :collection="collection"
          :identifiers="Event.identifiers || []"
          :id="id"
          value=""
        />
      </v-tab-item>
    </v-tabs>
  </base-material-dialog>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'
import StbM2021 from './stb_m_2021/event'
import StbW2023 from './stb_w_2023/event'
import StbLtf2024 from './stb_ltf_2024/event'
import Identifiers from '../../core/components/identifiers'

const query = `
  _id
  type
  identifiers { name value }
  ... on StbM2021 { name startDate endDate }
  ... on StbW2023 { name startDate endDate }
  ... on StbLtf2024 { name startDate endDate }
`

export default {
  name: 'event',

  components: {
    StbM2021,
    StbW2023,
    StbLtf2024,
    Identifiers
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {},
    tab: 0
  }),

  computed: {
    ...mapGetters(['isMaster']),
    collection () {
      switch (this.Event.type) {
        case 'stb_m_2021': return 'StbM2021'
        case 'stb_w_2023': return 'StbW2023'
        case 'stb_ltf_2024': return 'StbLtf2024'
        default: return ''
      }
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
    },
    async del () {
      if (!(await this.$root.$children[0].$refs.confirm.open('Wirklich löschen?', 'Soll das Event wirklich gelöscht werden? Dieser Vorgang kann nicht rückgängig gemacht werden!', { width: 400 }))) {
        return
      }

      this.mutate({
        mutation: gql`
          mutation($id: UUID!) {
            ${this.collection}Delete(id: $id)
          }
        `,
        variables: {
          id: this.id
        }
      })

      this.close()
    }
  },

  watch: {
    value () {
      this.Event = {}
    }
  },

  apollo: {
    Event: {
      query: gql`
        query($id: UUID!) { Event(id: $id) {
          ${query}
        }}
      `,
      subscribeToMore: {
        document: gql`
          subscription($id: UUID!) { EventUpdate(id: $id) {
            ${query}
          }}
        `,
        variables () {
          return { id: this.id }
        }
      },
      variables () {
        return { id: this.id }
      },
      skip () {
        return !this.id || this.id === ''
      }
    }
  }
}
</script>

<style scoped>

</style>
