<template>
  <v-card flat tile>
    <v-row>
      <v-col cols="12">
        <base-edit-dialog
          :value="Event.name"
          :id="id"
          label="Name"
          @input="({ value, id }) => savefield(id, 'name', value)"
        >
          <v-text-field
            :value="Event.name"
            label="Name"
            readonly
          />
        </base-edit-dialog>
      </v-col>
      <v-col cols="6">
        <base-edit-dialog-date
          :value="Event.startDate"
          :id="id"
          label="Beginn"
          dateformat="DD.MM.YYYY"
          @input="({ value, id }) => savefield(id, 'startDate', value, 'Date')"
        >
          <v-text-field
            :value="Event.startDate | dateformat('DD.MM.YYYY')"
            label="Beginn"
            readonly
          >
            <template #prepend>
              <v-icon>far fa-calendar-day</v-icon>
            </template>
          </v-text-field>
        </base-edit-dialog-date>
      </v-col>
      <v-col cols="6">
        <base-edit-dialog-date
          :value="Event.endDate"
          :id="id"
          label="Ende"
          dateformat="DD.MM.YYYY"
          @input="({ value, id }) => savefield(id, 'endDate', value, 'Date')"
        >
          <v-text-field
            :value="Event.endDate | dateformat('DD.MM.YYYY')"
            label="Ende"
            readonly
          >
            <template #prepend>
              <v-icon>far fa-calendar-day</v-icon>
            </template>
          </v-text-field>
        </base-edit-dialog-date>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue'
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'event',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    Event: {
      type: Object,
      required: true
    }
  },

  methods: {
    savefield (id, field, value, type) {
      if (!id) {
        Vue.set(this.Event, field, value)
        return
      }

      if (!type) type = 'String'

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            StbLtf2024${q}Update(id: $id, ${field}: $${field}) { _id }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
